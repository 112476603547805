export function delay(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, ms)
    })
}

export function waitForever(): Promise<void> {
    return new Promise(() => {
        // Left empty on purpose
    })
}

export function addHours(date: Date, hours: number) {
    date = new Date(date.valueOf())
    date.setUTCHours(date.getUTCHours() + hours)
    return date
}
