import React, { useEffect, useState } from "react"
import { payThunk } from "../../store/thunks/paymentThunks"
import useReduxDispatch from "../../store/useReduxDispatch"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getDescriptionByPaymentType, getLogoByPaymentType } from "../../services/logoService"
import {
    IPaymentLinkSupportedPaymentType,
    IResursConfiguration,
    TCurrency,
    TPaymentType,
} from "../../type/paymentPage/IPaymentPageData"
import "./ResursSection.scss"
import { paymentPageSelectors } from "../../store/reducers/paymentPageReducer"
import PaymentSectionContainer from "../layouts/PaymentSectionContainer"

type TResursTypes = Extract<TPaymentType, "ResursInvoice" | "ResursPartPayment" | "ResursRevolvingCredit">

export interface IResursDataProps {
    amount: number
    currency: TCurrency
    resursType: TResursTypes
    supportedPaymentType: IPaymentLinkSupportedPaymentType
}

export const ResursSection = ({ amount, currency, resursType, supportedPaymentType }: IResursDataProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const selectedPaymentType = useSelector(paymentPageSelectors.selectedPaymentType)
    const [isPaying, setIsPaying] = useState(false)

    if (
        selectedPaymentType != "ResursInvoice" &&
        selectedPaymentType != "ResursPartPayment" &&
        selectedPaymentType != "ResursRevolvingCredit"
    ) {
        return null
    }

    const configuration = supportedPaymentType.configuration as IResursConfiguration
    if (!configuration) {
        return null
    }

    const termsUrl = configuration.shouldAppendAmount ? `${configuration.termsUrl}${amount}` : configuration.termsUrl

    const onPay = async () => {
        setIsPaying(true)
        try {
            await dispatch(payThunk(selectedPaymentType))
        } catch (_) {
            // empty block
        }
        setIsPaying(false)
    }

    return (
        <PaymentSectionContainer
            className="resurs-section"
            amount={amount}
            currency={currency}
            paymentType={resursType}
            isLoading={false}
            onPay={onPay}
            isPaying={isPaying}
            headerLogos={[<img key={selectedPaymentType} className="resurs-logo" src={getLogoByPaymentType(resursType)}></img>]}
        >
            <div className="resurs-section__terms">
                <a href={termsUrl} target="_blank" rel="noopener noreferrer" className="resurs-section__terms-link">
                    <span className="icon icon-footer-social icon-website">
                        {t("paymentpage.seeTermsFor", { paymentType: getDescriptionByPaymentType(resursType) })}
                    </span>
                </a>
            </div>
        </PaymentSectionContainer>
    )
}

export default ResursSection
