import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { HttpMethod } from "../../type/request/HttpMethod"
import { StoreState } from "../StoreState"
import { requestThunk } from "./serverThunks"

interface ISendReceiptRequest {
    webshopId: string
    orderId: string
}

export const sendReceiptThunk =
    (data: ISendReceiptRequest): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const url = "/paymentLink/sendReceipt"
        return await dispatch(requestThunk<void>(HttpMethod.POST, url, { data }))
    }
