import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { HttpMethod } from "../../type/request/HttpMethod"
import { StoreState } from "../StoreState"
import { requestThunk } from "./serverThunks"

export interface IOrderInfoResponse {
    orderId: string
    platformOrderId: string
    webshopId: string
}

export const getOrderInfoThunk =
    (orderId: string): ThunkAction<Promise<IOrderInfoResponse>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        const url = "/paymentLink/orderInfo"
        return await dispatch(
            requestThunk<IOrderInfoResponse>(HttpMethod.GET, url, {
                params: {
                    orderId,
                },
            })
        )
    }
