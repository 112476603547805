import React from "react"
import { useTranslation } from "react-i18next"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import StandardButton from "../../components/buttons/StandardButton"
import cn from "classnames"
import "./PaymentSectionContainer.scss"
import { TPaymentType } from "../../type/paymentPage/IPaymentPageData"
import { getDescriptionByPaymentType } from "../../services/logoService"
import ChangePaymentMethodButton from "../buttons/changePaymentMethod/ChangePaymentMethodButton"

export interface IPaymentSectionProps {
    className?: string
    amount: number
    currency: string
    paymentType: TPaymentType
    isPaying: boolean
    onPay: () => void
    headerLogos?: JSX.Element[]
    isLoading?: boolean
    children?: React.ReactNode
}

export const PaymentSectionContainer = ({
    className,
    amount,
    currency,
    paymentType,
    headerLogos,
    isLoading,
    isPaying,
    onPay,
    children,
}: IPaymentSectionProps) => {
    const { t } = useTranslation()

    return (
        <SpinnerContainer showSpinner={isLoading ?? false}>
            <div className={cn("payment-section", className, { active: !isLoading })}>
                <div className="payment-section__header">
                    <div className="my-auto">
                        <span className="payment-section__header-title">{getDescriptionByPaymentType(paymentType)}</span>
                    </div>
                    {!!headerLogos && <div className="payment-section__header-logos">{headerLogos?.map((logo) => logo)}</div>}
                </div>
                {!!children && children}
                <SpinnerContainer showSpinner={isPaying}>
                    <StandardButton
                        dark
                        isLarge
                        noBorder
                        noBorderRadius
                        className="w-100 mb-3 rounded"
                        onClick={async () => onPay()}
                    >
                        {t("paymentpage.paybutton", {
                            amount: amount,
                            currency: currency,
                        })}
                    </StandardButton>
                </SpinnerContainer>
                <ChangePaymentMethodButton paymentType={paymentType} />
            </div>
        </SpinnerContainer>
    )
}

export default PaymentSectionContainer
