import {
    isPaymentTypeBrowserSupported,
    isPaymentTypeSupportedForAmount,
    hasPaymentTypePaymentComponent,
    isPaymentTypeSupportedInCurrentConfiguration,
} from "./../../services/paymentService"
import { getText } from "../../locale/lang"
import { IPaymentPageData, TPaymentType } from "../../type/paymentPage/IPaymentPageData"
import { ICheckPaymentStatusRedirectPageData } from "../../type/checkPaymentStatusRedirectPageData/ICheckPaymentStatusRedirectPageData"
import { paymentPageReducerActions, paymentPageSelectors } from "../reducers/paymentPageReducer"
import { requestThunk } from "./serverThunks"
import { ThunkAction } from "redux-thunk"
import { StoreState } from "../StoreState"
import { AnyAction } from "redux"
import { TModalResponseType } from "../../components/modal/common/modalTypes"
import { HttpMethod } from "../../type/request/HttpMethod"
import { hostedFieldsSelectors } from "../reducers/hostedFieldsReducer"
import { addModalThunk } from "./modalThunks"
import { getQueryValueFromCurrentUrl } from "../../util/urlUtil"
import { tryToSaveBrandSelection } from "../../services/brandPreferencesService"

export interface IModalResponse {
    type: TModalResponseType
    data?: any
}

export const triggerDelayedCallbackThunk =
    (token: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const resp = await dispatch(
            requestThunk<{ isCallbackSuccess: boolean; redirectUrl: string }>(
                HttpMethod.POST,
                `/triggerDelayedCallback/` + token,
                {
                    timeout: 10 * 60 * 1000,
                }
            )
        )
        document.location.href = resp.redirectUrl
    }

export const getPaymentPageDataThunk =
    (token: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        try {
            const language = getQueryValueFromCurrentUrl("language")
            const errorMessage = getQueryValueFromCurrentUrl("errorMessage")
            const statusCode = getQueryValueFromCurrentUrl("statusCode")

            const paymentPageData = await dispatch(
                requestThunk<IPaymentPageData>(
                    HttpMethod.GET,
                    `/paymentlink/Info?token=${token}&language=${language}&errorMessage=${errorMessage}&statusCode=${statusCode}`
                )
            )

            // Filter displayable payment types
            paymentPageData.supportedPaymentTypes = paymentPageData.supportedPaymentTypes
                .filter((supportedPaymentType) => isPaymentTypeBrowserSupported(supportedPaymentType.paymentType)) // some payment types are browser dependent, e.g., Apple Pay, so we need to check if the browser is supported
                .filter((supportedPaymentType) =>
                    isPaymentTypeSupportedForAmount(
                        supportedPaymentType.paymentType,
                        supportedPaymentType,
                        paymentPageData.amount,
                        paymentPageData.currency
                    )
                )
                .filter((supportedPaymentType) =>
                    isPaymentTypeSupportedInCurrentConfiguration(supportedPaymentType.paymentType, supportedPaymentType)
                )

            if (!paymentPageData.showAlternativePaymentMethods) {
                const defaultMethod = paymentPageData.supportedPaymentTypes.find((paymentType) => paymentType.isDefault)
                if (defaultMethod) {
                    paymentPageData.supportedPaymentTypes = [defaultMethod]
                }
            }

            if (
                !paymentPageData.isShowError &&
                !paymentPageData.showAlternativePaymentMethods &&
                paymentPageData.supportedPaymentTypes.length === 1 &&
                !hasPaymentTypePaymentComponent(paymentPageData.supportedPaymentTypes[0].paymentType)
            ) {
                // Start payment immediately
                await dispatch(payThunk(paymentPageData.supportedPaymentTypes[0].paymentType, paymentPageData))
                return
            }

            dispatch(paymentPageReducerActions.addPaymentPageData(paymentPageData))

            if (paymentPageData.isShowError) {
                let error = paymentPageData.errorMessage
                if (!error) {
                    error = getText("common.paymentFallbackError")
                }
                dispatch(
                    addModalThunk({
                        type: "error",
                        errorMessage: error,
                    })
                )
            }
        } catch (ex) {
            dispatch(paymentPageReducerActions.addPaymentPageData(undefined))
            throw ex
        }
    }

const getProcessPaymentMethodRequestDataThunk =
    (paymentType: TPaymentType): ThunkAction<Promise<any>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const brandSelection = paymentPageSelectors.brandSelection(getState())
        if (paymentType === "CreditCard") {
            return {
                paymentType,
                creditCard: {
                    ...(hostedFieldsSelectors.data(getState()) ?? {}),
                    brandPreferences: brandSelection,
                },
            }
        }
        if (paymentType === "MobilePayOnline") {
            return {
                paymentType,
                creditCard: {
                    brandPreferences: brandSelection,
                },
            }
        }
        if (paymentType === "ApplePay") {
            const preparePaymentApiResponse = paymentPageSelectors.preparePaymentApiResponse(getState(), paymentType)
            const applePayToken = paymentPageSelectors.applePayToken(getState())
            return {
                paymentType,
                preparedPayment: {
                    swiipeToken: preparePaymentApiResponse?.swiipeToken,
                    data: { applePayToken },
                },
            }
        }
        return {
            paymentType,
        }
    }

export const payThunk =
    (
        paymentType: TPaymentType,
        overridePaymentData?: IPaymentPageData
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const paymentData = overridePaymentData ?? paymentPageSelectors.data(getState())
        const paymentMethodData = await dispatch(getProcessPaymentMethodRequestDataThunk(paymentType))
        const brandSelection = paymentPageSelectors.brandSelection(getState())
        tryToSaveBrandSelection(paymentType, brandSelection, true)

        const res = await dispatch(
            requestThunk<{ redirectUrl: string; isShowError: boolean; error: string }>(
                HttpMethod.POST,
                paymentData!.processEndpoint,
                {
                    data: {
                        paymentMethod: paymentMethodData,
                    },
                }
            )
        )
        if (res.isShowError) {
            let error = res.error
            if (!error) {
                error = getText("common.paymentFallbackError")
            }
            dispatch(
                addModalThunk({
                    type: "error",
                    errorMessage: error,
                })
            )
        } else {
            document.location.href = res.redirectUrl
        }
    }

export const getRedirectPostPaymentThunk =
    (data: ICheckPaymentStatusRedirectPageData): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const url = `postPayment/redirect/${data.paymentType}`
        const res = await dispatch(
            requestThunk<{ redirectUrl: string; success: boolean; iframeParentOrigin: string }>(HttpMethod.GET, url, {
                params: {
                    successUrl: decodeURIComponent(data.successUrl),
                    failureUrl: decodeURIComponent(data.failureUrl),
                    cancelUrl: decodeURIComponent(data.cancelUrl),
                    iframeParentOrigin: decodeURIComponent(data.iframeParentOrigin),
                    language: data.language,
                    orderId: data.orderId,
                    transactionId: data.transactionId,
                    swTransactionId: data.swTransactionId,
                    userId: data.userId,
                    webshopId: data.webshopId,
                    merchantId: data.merchantId,
                    sessionId: data.sessionId,
                    shouldCheckS2sStatus: data.shouldCheckS2sStatus,
                    paymentProvider: data.paymentProvider,
                },
                timeout: 10 * 60 * 1000,
            })
        )
        if (!res.success && !res.redirectUrl) {
            await dispatch(addModalThunk({ type: "error", errorMessage: data.error }))
            return
        }

        if (inIframe()) {
            parent.postMessage(
                {
                    swiipeRedirect: {
                        success: res.success,
                        redirectUrl: res.redirectUrl,
                    },
                },
                res.iframeParentOrigin
            )
        } else {
            location.href = res.redirectUrl
        }
    }

const inIframe = () => {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
}

export interface IPreparePaymentInSwiipeApiResponse {
    swiipeToken: string
    data: any
    isSuccess: boolean
    errorMessage?: string
}
export const preparePaymentInSwiipeApiThunk =
    (
        token: string,
        paymentType: TPaymentType,
        data: any
    ): ThunkAction<Promise<IPreparePaymentInSwiipeApiResponse>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const existingResponse = paymentPageSelectors.preparePaymentApiResponse(getState(), paymentType)

        if (existingResponse) {
            return existingResponse
        }

        const url = `paymentLink/prepare/${token}`
        const response = await dispatch(
            requestThunk<IPreparePaymentInSwiipeApiResponse>(HttpMethod.POST, url, {
                data: {
                    paymentType,
                    data,
                },
            })
        )

        dispatch(paymentPageReducerActions.setPreparePaymentResponse(paymentType, response))
        return response
    }
