import { Navigation, createBrowserNavigation, Route, Matcher } from "navi"
import { INavigationContext } from "./../routes/INavigationContext"

export type TOnNavigate = (value: Route) => void
export type TShouldForceHideMenu = (forceShouldHide: boolean) => void

interface INavigationService {
    lastRoute?: string
    nav?: Navigation
    listeners: TOnNavigate[]
    forceHideMenuListeners: TShouldForceHideMenu[]
    forceHideMenus: boolean
    setup: (context: INavigationContext, routes: Matcher<INavigationContext, INavigationContext>) => Navigation
    navigate: (path: string) => void
    addNavigationListener: (listener: TOnNavigate) => () => void
    // If any components wants to know if logged in menus should be hidden
    addForceHideMenuListener: (listener: TShouldForceHideMenu) => () => void
}

const forceHideMenuRoutes: string[] = ["/mobilecheckout"]

export const navigationService: INavigationService = {
    listeners: [],
    forceHideMenuListeners: [],
    forceHideMenus: false,

    setup(context: INavigationContext, routes: Matcher<INavigationContext, INavigationContext>) {
        if (!this.nav) {
            this.nav = createBrowserNavigation<INavigationContext>({ routes, context })
            this.nav.subscribe((value: Route) => {
                this.lastRoute = value.url.href
                this.forceHideMenus = forceHideMenuRoutes.indexOf(value.url.pathname) >= 0
                this.forceHideMenuListeners.forEach((l) => l(this.forceHideMenus))
                this.listeners.forEach((l) => l(value))
            })
        }

        return this.nav
    },
    async navigate(path: string) {
        if (!!this.lastRoute && path === this.lastRoute) {
            // Already there or is already navigating
            return
        }
        if (!this.nav) {
            throw new Error("Navigation is not setup")
        }
        this.lastRoute = path
        return await this.nav.navigate(path)
    },
    addNavigationListener(listener: TOnNavigate) {
        this.listeners.push(listener)
        return () => {
            const index = this.listeners.indexOf(listener)
            this.listeners.splice(index, 1)
        }
    },
    addForceHideMenuListener(listener: TShouldForceHideMenu) {
        this.forceHideMenuListeners.push(listener)
        return () => {
            const index = this.forceHideMenuListeners.indexOf(listener)
            this.forceHideMenuListeners.splice(index, 1)
        }
    },
}
