import { IPreparePaymentInSwiipeApiResponse } from "../store/thunks/paymentThunks"
import { TCurrency } from "../type/paymentPage/IPaymentPageData"

export const initiateApplePaySession = (
    countryCode: string,
    currencyCode: TCurrency,
    label: string,
    amount: string,
    prepareInSwiipeApi: (data: any) => Promise<IPreparePaymentInSwiipeApiResponse>
) =>
    new Promise<string>((resolve, reject) => {
        if (!ApplePaySession) {
            reject()
            return
        }

        const total = {
            amount,
            label,
        }

        const session = new ApplePaySession(3, {
            countryCode,
            currencyCode,
            merchantCapabilities: ["supports3DS"],
            supportedNetworks: ["visa", "masterCard"],
            total,
        })

        session.onvalidatemerchant = async (event: any) => {
            const resp = await prepareInSwiipeApi({ validationUrl: event.validationURL })

            if (!resp.isSuccess) {
                session.abort()
                reject()
                return
            }

            session.completeMerchantValidation(resp.data)
        }

        session.onpaymentmethodselected = () => session.completePaymentMethodSelection(total, [])

        session.onpaymentauthorized = (event: any) => {
            const { token } = event.payment
            if (token) {
                session.completePayment(ApplePaySession.STATUS_SUCCESS)
                resolve(token)
                return
            }
            session.completePayment(ApplePaySession.STATUS_FAILURE)
            reject()
        }

        session.oncancel = () => reject()

        session.begin()
    })
