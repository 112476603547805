import React from "react"
import { mount, route } from "navi"
import PaymentPage from "../pages/PaymentPage"
import PaymentCancelledPage from "../pages/PaymentCancelledPage"
import PaymentFailedPage from "../pages/PaymentFailedPage"
import PaymentLinkCancelledPage from "../pages/PaymentLinkCancelledPage"
import WinbackFallbackPage from "../pages/WinbackFallbackPage"
import { INavigationContext } from "./INavigationContext"
import CheckPaymentStatusRedirectPage from "../pages/CheckPaymentStatusRedirectPage"
import PaymentSuccessPage from "../pages/PaymentSuccessPage"
import TriggerDelayedCallbackPage from "../pages/TriggerDelayedCallbackPage"

export const mainRoutes = mount<INavigationContext>({
    "/payment": route({
        view: <PaymentPage />,
    }),
    "/postPayment/checkPaymentStatus": route({
        view: <CheckPaymentStatusRedirectPage />,
    }),
    "/paymentSuccess": route({
        view: <PaymentSuccessPage />,
    }),
    "/paymentCancelled": route({
        view: <PaymentCancelledPage />,
    }),
    "/paymentFailed": route({
        view: <PaymentFailedPage />,
    }),
    "/paymentLinkCancelled": route({
        view: <PaymentLinkCancelledPage />,
    }),
    "/cachedBaskets/fallback": route({
        view: <WinbackFallbackPage />,
    }),
    "/triggerCallback/:token": route((req) => ({
        view: <TriggerDelayedCallbackPage token={req.params.token} />,
    })),
})
