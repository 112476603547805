import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import "./TriggerDelayedCallbackPage.scss"
import PageWrapper from "../components/page/PageWrapper"
import { triggerDelayedCallbackThunk } from "../store/thunks/paymentThunks"
import useReduxDispatch from "../store/useReduxDispatch"
import SpinnerContainer from "../components/buttons/SpinnerContainer"
import FormFooter from "../components/form/footer/FormFooter"

interface TriggerDelayedCallbackPageProps {
    token: string
}

const TriggerDelayedCallbackPage = ({ token }: TriggerDelayedCallbackPageProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    useEffect(() => {
        dispatch(triggerDelayedCallbackThunk(token))
    }, [])

    return (
        <PageWrapper>
            <div className="trigger-delayed-callback-page">
                <SpinnerContainer className="spinner-container" showSpinner={true}></SpinnerContainer>
                <FormFooter className="footer-img" />
            </div>
        </PageWrapper>
    )
}

export default TriggerDelayedCallbackPage
