import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import CreditCardFields from "../../components/creditCard/CreditCardFields"
import { HostedFields } from "hosted-fields-sdk"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import { getLogoByCardType } from "../../services/logoService"
import { TCreditCardType, IPaymentLinkSupportedPaymentType } from "../../type/paymentPage/IPaymentPageData"
import { payThunk } from "../../store/thunks/paymentThunks"
import "./CreditCardSection.scss"
import useReduxDispatch from "../../store/useReduxDispatch"
import PaymentSectionContainer from "../layouts/PaymentSectionContainer"
import BrandSelection from "../card/BrandSelection"
import ReservationNoticeText from "../text/ReservationNotice"

export interface ICreditCardDataProps {
    amount: number
    currency: string
    supportedPaymentType: IPaymentLinkSupportedPaymentType
}

export const CreditCardSection = ({ amount, currency, supportedPaymentType }: ICreditCardDataProps) => {
    const dispatch = useReduxDispatch()
    const [isPaying, setIsPaying] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const { t } = useTranslation()

    if (supportedPaymentType.paymentType != "CreditCard") {
        return null
    }

    const gatewayConfiguration = supportedPaymentType.configuration
    const supportedCards: TCreditCardType[] = gatewayConfiguration.supportedCardBrands

    const onPay = () => {
        try {
            setIsPaying(true)
            HostedFields.get() // Trigger GET to validate card info and dispatch payment if valid card data - see callback in CreditCardFields below
        } catch (_) {
            setIsPaying(false)
        }
    }

    return (
        <>
            <SpinnerContainer showSpinner={isLoading}>
                <PaymentSectionContainer
                    className="credit-card-section"
                    amount={amount}
                    currency={currency}
                    paymentType="CreditCard"
                    isLoading={isLoading}
                    isPaying={isPaying}
                    onPay={onPay}
                    headerLogos={supportedCards.map((card) => (
                        <img key={card} className="credit-card-section__card-logo" src={getLogoByCardType(card, true)}></img>
                    ))}
                >
                    <div className="credit-card-section">
                        <CreditCardFields
                            isProduction={gatewayConfiguration.isProduction}
                            merchantId={parseInt(gatewayConfiguration.merchantId)}
                            onLoadCallback={() => setIsLoading(false)}
                            callback={async (isSuccess: boolean) => {
                                try {
                                    if (isSuccess) {
                                        await dispatch(payThunk("CreditCard"))
                                    }
                                } catch (_) {
                                    // empty block
                                }
                                setIsPaying(false)
                            }}
                        />

                        <BrandSelection className="credit-card-section__brand-selection" paymentType="CreditCard" />

                        {currency === "DKK" && <ReservationNoticeText className="credit-card-section__reservation-notice" />}
                        {/* <ThreeDSecureMessageBox></ThreeDSecureMessageBox>  - hidden until needed */}
                    </div>
                </PaymentSectionContainer>
            </SpinnerContainer>
        </>
    )
}

export default CreditCardSection
