import React from "react"
import { IPaymentLinkSupportedPaymentType, TA2ACountry } from "../../type/paymentPage/IPaymentPageData"

import DanskeBankLogo from "../../images/a2aBankLogos/danske-bank-logo.png"
import NordeaLogo from "../../images/a2aBankLogos/nordea-logo.png"
import JyskeBankLogo from "../../images/a2aBankLogos/jyske-bank-logo.png"
import HandelsBankenLogo from "../../images/a2aBankLogos/handelsbanken-logo.svg"
import SebLogo from "../../images/a2aBankLogos/seb-logo.svg"
import SwedBankLogo from "../../images/a2aBankLogos/swedbank-logo.svg"

import "./A2APaymentTypeIcon.scss"
import { useTranslation } from "react-i18next"

export interface IA2APaymentTypeIconProps {
    paymentTypeData: IPaymentLinkSupportedPaymentType
}

interface IBankLogoConfig {
    src: string
    height: string
}

type TBankLogoMapping = {
    [countryCode in TA2ACountry]: IBankLogoConfig[]
}

const danskeBankLogo: IBankLogoConfig = { src: DanskeBankLogo, height: "12px" }
const nordeaLogo: IBankLogoConfig = { src: NordeaLogo, height: "12px" }
const jyskeBankLogo: IBankLogoConfig = { src: JyskeBankLogo, height: "12px" }

const handelsBankenLogo: IBankLogoConfig = { src: HandelsBankenLogo, height: "20px" }
const sebLogo: IBankLogoConfig = { src: SebLogo, height: "20px" }
const swedBankLogo: IBankLogoConfig = { src: SwedBankLogo, height: "20px" }

const getMarginByCountryCode = (countryCode: string): string => {
    if (!countryCode) {
        return "0"
    }

    const logoMappings = {
        DK: "5px",
        SE: "3px",
    }
    return logoMappings[countryCode] ?? "0"
}

const getBankLogosByCountryCode = (countryCode: string): IBankLogoConfig[] => {
    if (!countryCode) {
        return []
    }

    const logoMappings: TBankLogoMapping = {
        DK: [danskeBankLogo, nordeaLogo, jyskeBankLogo],
        SE: [swedBankLogo, sebLogo, handelsBankenLogo],
    }
    return logoMappings[countryCode] ?? []
}

export const A2APaymentTypeIcon = ({ paymentTypeData }: IA2APaymentTypeIconProps) => {
    const { t } = useTranslation()
    if (
        paymentTypeData.paymentType !== "AccountToAccount" ||
        (paymentTypeData.configuration.supportedCountries ?? []).length === 0
    ) {
        return null
    }

    const countryCode = paymentTypeData.configuration.supportedCountries[0]
    const logos = getBankLogosByCountryCode(countryCode)

    return (
        <>
            <div className="a2a-payment-type-icon">
                {logos.map((l) => {
                    return (
                        <img
                            key={l.src}
                            src={l.src}
                            style={{
                                height: l.height,
                                marginTop: getMarginByCountryCode(countryCode),
                                marginBottom: getMarginByCountryCode(countryCode),
                            }}
                        />
                    )
                })}
                <span className="a2a-payment-type-icon__more">{t("accountToAccount.plusMore").toUpperCase()}</span>
            </div>
        </>
    )
}

export default A2APaymentTypeIcon
