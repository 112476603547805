import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./PaymentPage.scss"
import PageWrapper from "../components/page/PageWrapper"
import { getQueryValueFromCurrentUrl } from "../util/urlUtil"
import { useSelector } from "react-redux"
import { getPaymentPageDataThunk } from "../store/thunks/paymentThunks"
import { paymentPageSelectors } from "../store/reducers/paymentPageReducer"
import FormFooter from "../components/form/footer/FormFooter"
import SpinnerContainer from "../components/buttons/SpinnerContainer"
import CreditCardSection from "../components/creditCard/CreditCardSection"
import StandardButton from "../components/buttons/StandardButton"
import { sortAscending } from "../util/arrayUtil"
import { getPaymentTypeSortingValue, hasPaymentTypePaymentComponent } from "../services/paymentService"
import useReduxDispatch from "../store/useReduxDispatch"
import { IPaymentPageData, TPaymentType } from "../type/paymentPage/IPaymentPageData"
import PaymentTypeButton from "../components/buttons/PaymentTypeButton"
import ResursSection from "../components/otherMethods/ResursSection"
import cn from "classnames"
import ApplePaySection from "../components/applePay/ApplePaySection"

interface IPaymentPageProps {}

const PaymentPage = ({}: IPaymentPageProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const token = getQueryValueFromCurrentUrl("token")
    const paymentPageData = useSelector(paymentPageSelectors.data)
    const dataWasFetched = useSelector(paymentPageSelectors.dataWasFetched)
    const selectedPaymentType = useSelector(paymentPageSelectors.selectedPaymentType)

    useEffect(() => {
        dispatch(getPaymentPageDataThunk(token))
    }, [])

    if (!paymentPageData && dataWasFetched) {
        return null
    }

    const shouldShowCompleteHeader = hasPaymentTypePaymentComponent(selectedPaymentType)
    const paymentComponent = tryGetPaymentComponent(paymentPageData, selectedPaymentType)

    return (
        <PageWrapper narrow>
            <SpinnerContainer showSpinner={!dataWasFetched}>
                <div id="payment-page">
                    <div className="payment-page-header">
                        <span className="amount-header">
                            {t("paymentpage.orderAmount", {
                                amount: paymentPageData?.amount,
                                currency: paymentPageData?.currency,
                            })}
                        </span>
                        <span>
                            {t("paymentpage.orderId")}: {paymentPageData?.platformOrderId}
                        </span>
                    </div>
                    <hr />
                    <div
                        className={cn("payment-page-header__description", {
                            "payment-page-header__description--unbold": shouldShowCompleteHeader,
                        })}
                    >
                        {shouldShowCompleteHeader ? t("paymentpage.completePayment") : t("paymentpage.chooseMethod")}
                    </div>
                    {paymentComponent ?? (
                        <div className="payment-page-methods">
                            {sortAscending(paymentPageData?.supportedPaymentTypes ?? [], (p) =>
                                getPaymentTypeSortingValue(p.paymentType)
                            ).map((supportedPaymentType) => (
                                <PaymentTypeButton
                                    currency={paymentPageData?.currency}
                                    key={supportedPaymentType.paymentType}
                                    paymentTypeData={supportedPaymentType}
                                />
                            ))}
                        </div>
                    )}
                    {!!paymentPageData?.cancelRedirectUrl && (
                        <div className="cancel-button-container">
                            <div className="helper"></div>
                            <StandardButton
                                noBorder
                                noBorderRadius
                                className="cancel-button rounded"
                                onClick={async () => (document.location.href = paymentPageData?.cancelRedirectUrl ?? "")}
                            >
                                {t("paymentpage.cancelbutton")}
                            </StandardButton>
                            <div className="helper"></div>
                        </div>
                    )}
                </div>
                <FormFooter />
            </SpinnerContainer>
        </PageWrapper>
    )
}

const tryGetPaymentComponent = (paymentPageData?: IPaymentPageData, selectedPaymentType?: TPaymentType) => {
    if (!paymentPageData || !selectedPaymentType) {
        return null
    }

    const selectedPaymentTypeData = paymentPageData.supportedPaymentTypes.find((pt) => pt.paymentType === selectedPaymentType)
    if (!selectedPaymentTypeData) {
        return null
    }

    switch (selectedPaymentType) {
        case "CreditCard":
            return (
                <CreditCardSection
                    amount={paymentPageData.amount}
                    currency={paymentPageData.currency}
                    supportedPaymentType={selectedPaymentTypeData}
                />
            )
        case "ResursInvoice":
            return (
                <ResursSection
                    amount={paymentPageData.amount}
                    currency={paymentPageData.currency}
                    supportedPaymentType={selectedPaymentTypeData}
                    resursType="ResursInvoice"
                />
            )
        case "ResursPartPayment":
            return (
                <ResursSection
                    amount={paymentPageData.amount}
                    currency={paymentPageData.currency}
                    supportedPaymentType={selectedPaymentTypeData}
                    resursType="ResursPartPayment"
                />
            )
        case "ResursRevolvingCredit":
            return (
                <ResursSection
                    amount={paymentPageData.amount}
                    currency={paymentPageData.currency}
                    supportedPaymentType={selectedPaymentTypeData}
                    resursType="ResursRevolvingCredit"
                />
            )
        case "ApplePay":
            return (
                <ApplePaySection
                    amount={paymentPageData.amount}
                    currency={paymentPageData.currency}
                    hostName={paymentPageData.hostName}
                    country={paymentPageData.country}
                    platformOrderId={paymentPageData.platformOrderId}
                />
            )
        default:
            return null
    }
}

export default PaymentPage
