import React, { useState } from "react"
import SpinnerContainer from "../buttons/SpinnerContainer"
import { payThunk } from "../../store/thunks/paymentThunks"
import { getLogoByPaymentType, getDescriptionByPaymentType } from "../../services/logoService"
import "./PaymentTypeButton.scss"
import { IPaymentLinkSupportedPaymentType, TCurrency } from "../../type/paymentPage/IPaymentPageData"
import { paymentPageReducerActions } from "../../store/reducers/paymentPageReducer"
import useReduxDispatch from "../../store/useReduxDispatch"
import { useTranslation } from "react-i18next"
import CreditCardPaymentTypeIcon from "../creditCard/CreditCardPaymentTypeIcon"
import { hasPaymentTypePaymentComponent } from "../../services/paymentService"
import A2APaymentTypeIcon from "../otherMethods/A2APaymentTypeIcon"
import BrandSelection from "../card/BrandSelection"
import ReservationNoticeText from "../text/ReservationNotice"

export interface IPaymentButtonProps {
    currency?: TCurrency
    paymentTypeData: IPaymentLinkSupportedPaymentType
}

//
// If the secure page has a custom payment page for given payment method,
// then set the payment type to the custom payment type instead of starting the process when
// button is clicked.
//
export const PaymentTypeButton = ({ paymentTypeData, currency }: IPaymentButtonProps) => {
    const dispatch = useReduxDispatch()
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)

    const paymentType = paymentTypeData.paymentType

    const hasSwiipeCustomPaymentPage = () => {
        switch (paymentType) {
            case "CreditCard":
                return !paymentTypeData.useFallback
            default:
                return hasPaymentTypePaymentComponent(paymentType)
        }
    }

    const renderIcons = () => {
        if (paymentType === "CreditCard") {
            return <CreditCardPaymentTypeIcon paymentTypeData={paymentTypeData} />
        }
        if (paymentType === "AccountToAccount") {
            return <A2APaymentTypeIcon paymentTypeData={paymentTypeData} />
        }
        return <img className="payment-method-logo" src={getLogoByPaymentType(paymentType)} alt={paymentType} />
    }

    return (
        <>
            <div className="payment-type-button">
                <SpinnerContainer showSpinner={isLoading}>
                    <div
                        className="payment-method-button"
                        onClick={async () => {
                            try {
                                if (hasSwiipeCustomPaymentPage()) {
                                    dispatch(paymentPageReducerActions.setSelectedPaymentType(paymentType))
                                    return
                                }
                                setIsLoading(true)
                                await dispatch(payThunk(paymentType))
                            } catch (error) {
                                setIsLoading(false)
                            }
                            setIsLoading(false)
                        }}
                        title={getDescriptionByPaymentType(paymentType)}
                    >
                        <span>
                            <input className="payment-radio-button" type="radio" checked={false} readOnly={true} />
                            {getDescriptionByPaymentType(paymentType)}{" "}
                        </span>
                        {renderIcons()}
                        {paymentType === "MobilePayOnline" && (
                            <BrandSelection
                                className="payment-type-button__brand-selection"
                                paymentType={paymentType}
                                addTopSpaceWhenForced
                            />
                        )}
                        {currency === "DKK" && paymentType === "MobilePayOnline" && (
                            <ReservationNoticeText className="payment-method-button-reservation-notice" />
                        )}
                    </div>
                </SpinnerContainer>
            </div>
        </>
    )
}

export default PaymentTypeButton
