import React, { Dispatch, useEffect, useState } from "react"
import "./CreditCardFields.scss"
import { useTranslation } from "react-i18next"
import { HostedFields, Field, FieldTypes, IHostedFieldsCallback } from "hosted-fields-sdk"
import { hostedFieldsReducerActions } from "../../store/reducers/hostedFieldsReducer"
import SpinnerContainer from "../buttons/SpinnerContainer"
import useReduxDispatch from "../../store/useReduxDispatch"
const cvvInfoImage = require("../../images/icons/cvc-info.png")

export interface ICreditCardFieldsProps {
    onLoadCallback: () => void
    callback: (anyErrors: boolean) => void
    isProduction: boolean
    merchantId: number
}

function getFieldConfig(t: (key: string) => string) {
    const cardHolderFields: Field[] = []

    const creditCardTitle = t("creditcardfields.number")
    const creditCardError = t("creditcardfields.invalidnumber")
    const creditCard: Field = {
        type: FieldTypes.CREDITCARD_NUMBER,
        id: "frmCCNum",
        name: "cardnumber",
        error: creditCardError,
        key: creditCardTitle,
        helpKey: creditCardTitle,
        visible: true,
        required: true,
        noAttributeValueFormatting: true,
        autocomplete: "cc-number",
        showIcon: true,
        pattern: "[0-9]*",
    }

    const expiryTitle = t("creditcardfields.expirydate")
    const expiryError = t("creditcardfields.invalidexpiredate")
    const expiry: Field = {
        type: FieldTypes.EXPIRY_MM_YYYY,
        autocomplete: "cc-exp",
        helpKey: expiryTitle,
        id: "frmCCExp",
        error: expiryError,
        key: "creditcard.main.expirymmyyyy",
        name: "cc-exp",
        noAttributeValueFormatting: true,
        pattern: "[0-9]*",
        required: true,
        requiredNewPayment: true,
        requiredRepeatPayment: false,
        showIcon: true,
        value: undefined,
        visible: true,
        visibleOnNewPayment: true,
        visibleOnRepeatPayment: false,
    }

    const cvvTitle = t("creditcardfields.cvv")
    const cvvError = t("creditcardfields.invalidcvv")
    const cvvInfoText = t("creditcardfields.cvvinfotext")
    const cvv: Field = {
        type: FieldTypes.CVV,
        autocomplete: "cc-csc",
        helpKey: cvvTitle,
        label: cvvInfoText,
        error: cvvError,
        id: "frmCCCVC",
        key: "creditcard.main.cvc",
        name: "cvc",
        noAttributeValueFormatting: true,
        pattern: "[0-9]*",
        required: true,
        requiredNewPayment: true,
        requiredRepeatPayment: true,
        showIcon: true,
        value: undefined,
        visible: true,
        visibleOnNewPayment: true,
        visibleOnRepeatPayment: true,
    }

    return [...cardHolderFields, creditCard, expiry, cvv]
}

const fieldsId = "credt-card-fields"
const fieldsInitializedSufix = "-initialized"

function initializeHostedFields(
    fields: Field[],
    isProduction: boolean,
    merchantId: number,
    dispatch: Dispatch<any>,
    onLoadCallback: () => void,
    callback: (anyErrors: boolean) => void
) {
    const initializedClass = `${fieldsId}${fieldsInitializedSufix}`
    if (document.getElementsByClassName(initializedClass).length > 0) {
        return
    }
    document.getElementById(fieldsId)!.classList.add(initializedClass)

    HostedFields.setup({
        merchantId: merchantId,
        renderMode: "single",
        hostedfieldsurl: isProduction
            ? "https://hostedpages.paymentiq.io/1.0.36/index.html"
            : "https://test-hostedpages.paymentiq.io/1.0.36/index.html",
        fields: fields,
        service: "", // Only required for AstropayCard
        styles: "/* RenderAsFloatingLabel */" + require("./creditCardHostedFieldsStyle.ts").default,
        autoFocusNext: true,
        callback: () => (data: IHostedFieldsCallback) => {
            dispatch(
                hostedFieldsReducerActions.addHostedFieldsData({
                    encCreditCardNumber: data.encCreditcardNumber,
                    expiryMonth: data.expiryMonth,
                    expiryYear: data.expiryYear,
                    encCvv: data.encCvv,
                })
            )
            callback(!data.errors)
        },
        onLoadCallback: () => onLoadCallback,
        el: `#${fieldsId}`,
    })
}

export const CreditCardFields = ({ callback, onLoadCallback, isProduction, merchantId }: ICreditCardFieldsProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const [isDataFetched, setIsDataFetched] = useState(false)
    const onLoadCallbackFunc = () => {
        setIsDataFetched(true)
        onLoadCallback()
    }

    useEffect(() => {
        const fields = getFieldConfig(t)
        initializeHostedFields(fields, isProduction, merchantId, dispatch, onLoadCallbackFunc, callback)
        setIsDataFetched(false)
    }, [])
    return (
        <SpinnerContainer showSpinner={!isDataFetched}>
            <div id={fieldsId}></div>
        </SpinnerContainer>
    )
}

export default CreditCardFields
