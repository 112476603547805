import React from "react"
import { IModalBase, IModalControlProps } from "./common/modalTypes"
import BaseModal from "./common/BaseModal"
import ModalFooter from "./common/ModalFooter"
import { ModalBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import "./ModalError.scss"

export interface IModalError extends IModalBase {
    type: "error"
    errorMessage: string
}

const ModalError = ({ title, errorMessage, callback, ...otherProps }: IModalError & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal
            {...otherProps}
            modalClassName="error-modal"
            callback={callback}
            centered
            title={title || t("common.error_title")}
        >
            <ModalBody>
                <div>
                    <p className="mb-0 text-center errormessage-text">{errorMessage}</p>
                </div>
            </ModalBody>
            <ModalFooter rightButton={{ onClick: () => callback("accepted"), text: t("common.close") }} />
        </BaseModal>
    )
}

export default ModalError
