import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./PaymentCancelledPage.scss"
import PageWrapper from "../components/page/PageWrapper"
import FormFooter from "../components/form/footer/FormFooter"
import SuccessTitleText from "../components/text/SuccessTitleText"
import useReduxDispatch from "../store/useReduxDispatch"
import { getOrderInfoThunk, IOrderInfoResponse } from "../store/thunks/orderThunks"
import { getQueryValueFromCurrentUrl } from "../util/urlUtil"
import SpinnerContainer from "../components/buttons/SpinnerContainer"
import { sendReceiptThunk } from "../store/thunks/paymentLinkThunks"

interface IPaymentSuccessPageProps {}

const PaymentSuccessPage = ({}: IPaymentSuccessPageProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [orderInfo, setOrderInfo] = useState<IOrderInfoResponse | undefined>(undefined)

    useEffect(() => {
        const orderId = getQueryValueFromCurrentUrl("orderId")

        if (!orderId) {
            return
        }

        ;(async () => {
            const orderInfo = await dispatch(getOrderInfoThunk(orderId))
            setOrderInfo(orderInfo)

            // Try send automatic receipt
            dispatch(sendReceiptThunk({ orderId: orderInfo.orderId, webshopId: orderInfo.webshopId }))
        })()
    }, [])

    return (
        <PageWrapper narrow>
            <SpinnerContainer showSpinner={!orderInfo}>
                <div id="payment-cancelled-page">
                    <SuccessTitleText
                        title={t("paymentSuccessPage.title")}
                        successTitle={t("paymentSuccessPage.successTitle")}
                        successSubtitle={t("paymentSuccessPage.orderIdTitle", { orderId: orderInfo?.platformOrderId })}
                        bottomText={t("paymentSuccessPage.bottomText")}
                    />
                </div>
            </SpinnerContainer>
            <FormFooter />
        </PageWrapper>
    )
}

export default PaymentSuccessPage
