import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import "./CheckPaymentStatusRedirectPage.scss"
import PageWrapper from "../components/page/PageWrapper"
import { getQueryValueFromCurrentUrl } from "../util/urlUtil"
import SpinnerContainer from "../components/buttons/SpinnerContainer"
import FormFooter from "../components/form/footer/FormFooter"
import { ICheckPaymentStatusRedirectPageData } from "../type/checkPaymentStatusRedirectPageData/ICheckPaymentStatusRedirectPageData"
import { getRedirectPostPaymentThunk } from "../store/thunks/paymentThunks"
import useReduxDispatch from "../store/useReduxDispatch"

const CheckPaymentStatusRedirectPage = ({}) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    useEffect(() => {
        const postPaymentData: ICheckPaymentStatusRedirectPageData = {
            cancelUrl: getQueryValueFromCurrentUrl("cancelUrl"),
            failureUrl: getQueryValueFromCurrentUrl("failureUrl"),
            successUrl: getQueryValueFromCurrentUrl("successUrl"),
            language: getQueryValueFromCurrentUrl("language"),
            paymentType: getQueryValueFromCurrentUrl("paymentType"),
            orderId: getQueryValueFromCurrentUrl("orderId"),
            transactionId: getQueryValueFromCurrentUrl("transactionId"),
            swTransactionId: getQueryValueFromCurrentUrl("swTransactionId"),
            userId: getQueryValueFromCurrentUrl("userId"),
            webshopId: getQueryValueFromCurrentUrl("webshopId"),
            merchantId: getQueryValueFromCurrentUrl("merchantId"),
            sessionId: getQueryValueFromCurrentUrl("sessionId"),
            iframeParentOrigin: getQueryValueFromCurrentUrl("iframeParentOrigin"),
            shouldCheckS2sStatus: getQueryValueFromCurrentUrl("shouldCheckS2sStatus") === "true",
            paymentProvider: getQueryValueFromCurrentUrl("paymentProvider"),
            error: t("common.error"),
        }
        dispatch(getRedirectPostPaymentThunk(postPaymentData))
    }, [])

    return (
        <PageWrapper>
            <div className="checkPaymentStatusRedirectPage">
                <SpinnerContainer className="spinner-container" showSpinner={true}></SpinnerContainer>
                <FormFooter className="footer-img" />
            </div>
        </PageWrapper>
    )
}

export default CheckPaymentStatusRedirectPage
