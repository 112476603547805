import React from "react"
import "./SuccessTitleText.scss"

interface ISuccessTitleTextProps {
    title: string
    successTitle: string
    successSubtitle: string
    bottomText: string
}

const SuccessTitleText = ({ title, successTitle, successSubtitle, bottomText }: ISuccessTitleTextProps) => {
    return (
        <div className="success-title-text-container">
            <div className="title">{title}</div>
            <div className="success-title">{successTitle}</div>
            <div className="success-subtitle">{successSubtitle}</div>
            <div className="text-bottom">{bottomText}</div>
        </div>
    )
}

export default SuccessTitleText
